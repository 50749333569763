<template>
  <MainBody />
</template>

<script>
import MainBody from "@/components/MainBody";

export default {
  components: {MainBody}
}
</script>